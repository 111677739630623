/**
 * @file index.jsx
 * @description Home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";

/**
 * @function IndexPage
 * @returns {JSX.Element} Home page.
 */

function IndexPage() {
  return (
    <>
      <Meta
        title="Home"
        description="This extension automatically submits the multi-factor authentication form."
      />
      <MediumHero
        title="Multi-Factor-Authentication: Autosubmit"
        subtitle="This extension automatically submits the multi-factor authentication form."
        buttonText="Download"
        buttonLink="/download"
      />
      <div className="section">
        <div className="container">
          <h2 className="title is-4">
            Quality of Life: Submit the 2FA-Form automatically
          </h2>
          <p>
            You log in and have to use multifactor authentication. You open a
            2FA app on your cell phone and use your numpad on the keyboard to
            type in the 6-digit code. Now you have to reach for the mouse, as
            the code is not sent automatically. You may additionally have only a
            few seconds until the code expires.... That's the end of it now.
            This plugin will send the form automatically as soon as you have
            entered the necessary number of digits.
          </p>
        </div>
      </div>
    </>
  );
}

export default IndexPage;

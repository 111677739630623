import React from "react";
import FullheightHero from "../components/fullheightHero";
import Meta from "../components/Meta";

function NotFoundPage(props) {
  return (
    <>
      <Meta
        title="404"
        description="The page you are looking for does not exist."
        noindex={true}
      />
      <FullheightHero
        title="404"
        subtitle="The page you are looking for does not exist."
        buttonText="Go back to the homepage"
        buttonLink="/"
        color="is-danger"
      />
    </>
  );
}

export default NotFoundPage;
